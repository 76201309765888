<template>
  <div style="width: 100%;height: 33%;">
    <div class="grouptitle">
绿电消纳率/替代率</div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
    height: 16px; height: 16px; margin-right: 8.5px"/>
    <chartBoard ref="chart1" style="    height: calc(100% - 50px);"  :option="chartOption1" />
  </div>
</template>

<script>
import 'echarts-gl'
export default {
  name: "powerBox16",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
            
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "bigdeptcode": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
          this.getData2();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      xiao:"",
      ti:"",
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      newarr1:[],
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        {
          code: "按周",
          name: "周",
        },
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      gridLeft: [],
      gridright: [],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "1AT1_进线柜", //选中的项目
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#51A34A", "rgba(81,163,74,0.3)", "#F03040"],
    };
  },
  computed: {
    // 当前登绿用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },

    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    transformSearch2: function () {
          var TYPE = this.searchObj.TYPE;
          var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
          var VALUE = this.$moment(dateStr);
          var monthVALUE = this.$moment(dateStr).startOf("month");
          var obj = {
            按天: {
              type: "day",
              startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
              endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
            },
            按周: {
              type: "week",
              startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
              endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
            },
            按月: {
              type: "month",
              startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
              endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
            },
            按年: {
              type: "year",
              startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
              endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
            },
          };
          return obj[TYPE];
        },
    //每月报警预警数
    getData() {
      var deptCode = "JG221027043";

      var url = Config.dev_url + "/api-apps/eskpi/green/energy/absorption";
      var data = {
        // deptCode: deptCode,
        deptCode: this.bigdeptcode,
        // queryType:"year",
        // startDate:"2022-1-01 00:00:00",
        // endDate: "2022-12-10 23:59:59"
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.detailInfo = {};
            let newarr = [];
            this.ti = res.data.data.average.toString();
            
            this.initEchart();
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData2() {
      var deptCode = "JG221027043";

      var url = Config.dev_url + "/api-apps/eskpi/energy/absorption";
      var data = {
        // deptCode: deptCode,
        deptCode: this.bigdeptcode,
        // queryType:"year",
        // startDate:"2022-1-01 00:00:00",
        // endDate: "2022-12-10 23:59:59"
      };
      Object.assign(data, this.transformSearch2());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
            this.detailInfo = {};
            let newarr = [];
            this.xiao = res.data.data.absorptionRate.toString();
           
            this.initEchart();
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    //去重累加
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    getData0() {
      this.detailInfo = {};
      var chart1 = {
        categoryData: [
          "8:01",
          "8:02",
          "8:03",
          "8:04",
          "8:05",
          "8:06",
          "8:07",
          "8:08",
          "8:09",
          "8:10",
          "8:11",
          "8:12",
        ],
        seriesData: [
          {
            name: "市使用量",
            value: [
              200, 169, 210, 129, 181, 243, 230, 429, 415, 426, 796, 1212,
            ],
          },
          {
            name: "绿电使用量",
            value: [
              835, 836, 837, 938, 935, 934, 1290, 1320, 1300, 1290, 1320, 1340,
            ],
          },
          {
            name: "平均绿电替代率",
            value: [70, 91, 71, 85, 78, 85, 72, 78, 80, 92, 88, 71],
          },
        ],
      };
      this.detailInfo.chart1 = chart1;
      this.initEchart();
    },
    initEchart() {
      var dom = this.$refs["chart1"];
      setTimeout(() => {
        if (!!dom) {
          dom.handleWindowResize();
        }
      }, 30);
      var data = [
      {
          name: '绿电消纳率',
          value: parseInt(this.xiao)
      },{
          name: '绿电替代率',
          value: parseInt(this.ti)
      }]
      var titleArr= [], seriesArr=[];
      var colors=[['#389af4', '#dfeaff'],['#ff8c37', '#ffdcc3'],['#ffc257', '#ffedcc'], ['#fd6f97', '#fed4e0'],['#a181fc', '#e3d9fe']]
      data.forEach(function(item, index){
          titleArr.push(
              {
                  text:item.name,
                  left: index * 50 + 24 +'%',
                  top: '80%',
                  textAlign: 'center',
                  textStyle: {
                      fontWeight: 'normal',
                      fontSize: '16',
                      color: 'white',
                      textAlign: 'center',
                  },
              }        
          );
          seriesArr.push(
              {
                  name: item.name,
                  type: 'pie',
                  clockWise: false,
                  radius: [60, 70],
                  itemStyle:  {
                      normal: {
                          color: colors[index][0],
                          shadowColor: colors[index][0],
                          shadowBlur: 0,
                          label: {
                              show: false
                          },
                          labelLine: {
                              show: false
                          },
                      }
                  },
                  hoverAnimation: false,
                  center: [index * 40 + 30 +'%', '50%'],
                  data: [{
                      value: item.value,
                      label: {
                          normal: {
                              formatter: function(params){
                                  return params.value+'%';
                              },
                              position: 'center',
                              show: true,
                              textStyle: {
                                  fontSize: '20',
                                  fontWeight: 'bold',
                                  color: colors[index][0]
                              }
                          }
                      },
                  }, {
                      value: 100-item.value,
                      name: 'invisible',
                      itemStyle: {
                          normal: {
                              color: colors[index][1]
                          },
                          emphasis: {
                              color: colors[index][1]
                          }
                      }
                  }]
              }    
          )
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "2%",
          right: "1",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          show:false,
          right: 0,
          top: "0%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        title:titleArr,
        // 控住柱状图样式
        // series:series,
        series: [{
            name: "",
            type: "gauge",
            center: ["25%", "45%"],
            radius: "70%",
            min: 0,
            max: 100,
            data: [{
                "value": this.xiao,
                "name": "1"
            }],
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2']
                ]
              }
            },
            splitLine: {
                show: false
            },
            detail:{
              color:'white',
              fontWeight:"normal",
              fontSize:"20",
              padding: 10,
              offsetCenter:[0, '70%'],
              formatter: function (value) {
                  return value+'%';
              }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            pointer: { //指针样式
                length: '50%',
                color: "#4A90E2"
            },
            title: {
                show: false,
                offsetCenter: [0, '100%'],
                color: '',
                fontSize: 16,
                backgroundColor: "",
                borderRadius: 5,
                padding: 10
            },
        },{
            name: "",
            type: "gauge",
            center: ["75%", "45%"],
            radius: "70%",
            min: 0,
            max: 100,
            data: [{
                "value": this.ti,
                "name": "1"
            }],
            detail:{
              color:'white',
              fontWeight:"normal",
              fontSize:"20",
              padding: 20,
              offsetCenter:[0, '70%'],
              formatter: function (value) {
                  return value+'%';
              }
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2']
                ]
              }
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            pointer: { //指针样式
                length: '50%',
                color: "#4A90E2"
            },
            title: {
                show: false,
                offsetCenter: [0, '100%'],
                color: '',
                fontSize: 18,
                backgroundColor: "",
                borderRadius: 5,
                padding: 10
            },
        },

        ]
        
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
      this.getData2();
    }
  },
};
</script>

<style >
.grouptitle{
  color: white;
  font-size: 0.2rem;
}
</style>
<template>
  <div style="width: 100%;height: 33%;">
    <div class="grouptitle">度电成本分析</div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
    height: 16px; height: 16px; margin-right: 8.5px"/>
    <chartBoard ref="chart2" style="    height: calc(100% - 50px);" :option="chartOption1" />
  </div>
</template>

<script>

export default {
  name: "powerBox22",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "bigdeptcode": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        {
          code: "按周",
          name: "周",
        },
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      gridLeft: [],
      gridright: [],
      panelOpen: false,
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        checked: false,
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#3366FF", "#FF841F"],
    };
  },
  computed: {
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    checkedChange() {
      this.initEchart();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "minute15",
          startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
    },
    //每月报警预警数
    getData() {
      let data = {
        deptCode: this.bigdeptcode,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps/ectricity/energy/price",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.detailInfo = {
            // cost1: result.cost1,
            // cost2: result.cost2,
            comparePercent1: result.comparePercent1,
            comparePercent2: result.comparePercent2,
          };
          var chart1 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          this.detailInfo.chart1 = chart1;
          let newarr = [];
          // console.log(this.detailInfo.chart1.seriesData[0].value);
          this.detailInfo.chart1.seriesData[0].value.forEach((item) => {
            // console.log(item);
            // item.value.forEach((val) => {
            //   newarr.push(val);
            // });
            newarr.push(item);
          });
          console.log(this.getNull(newarr), newarr.length);
          if (this.getNull(newarr) == newarr.length) {
            this.gridLeft = [0, 0, 0, 70];
            this.gridright = [0, 33, 0, 0];
          } else {
            this.gridLeft = [0, 0, 0, 20];
            this.gridright = [0, 0, 0, 0];
          }
          this.initEchart();
          this.$forceUpdate();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var series = [];
      seriesData.forEach((item) => {
        var obj = {
          type: "line",
          name: item.name,
          data: item.value,
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          smooth: false,
          emphasis: {
            focus: "series",
          },
          // barGap:0,
          // barWidth: 10,
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "2%",
          right: "2%",
          bottom: "7%",
          top: "17%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "10%",
          //orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
       
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
        },
        yAxis: {
          type: "value",
          name: "（元/kWh）",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
            padding: this.gridLeft,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        // 控住柱状图样式
        series: this.searchObj.checked ? series : [series[0]],
        color: this.colorList,
      };

      this.updateChart("chart2", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style >
.grouptitle{
  color: white;
  font-size: 0.2rem;
}
</style>
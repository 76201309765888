<template>
  <div class="bigbg" style="">
    <div class="top">
      <div class="bigelement">
        
        <div style="width: 100%;height: 60px;position: absolute;top: 0px;display: flex;">
        <el-select v-model="value" placeholder="" style="    margin-top: 4px;z-index: 99;" @change="delCard">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
          </el-select>
          <div class="bigheard" style="width: calc(100% - 40px);height: 100%;position: absolute;left: 0px;display: flex;
    justify-content: center;align-items: center;">
            <div style="    display: flex;align-items: center;">
              <img style="width: 71px;
    height: 27px;" src="@/assets/imgs/logo-color.png" />
      <!-- <img style="width: 71px;
    height: 27px;" src="@/assets/imgs/login/abb.svg" /> -->
              <div style="color: white;
    font-size: 30px;
    font-weight: 600;letter-spacing: 10px;
    margin-left: 10px;">财务管理驾驶舱</div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="main">
      <div class="left">
        <group style=""></group>
        <group2 style=""></group2>
        <group3 style=""></group3>
      </div>
      <div class="mind">
        <group7></group7>
        <group8></group8>
        <group9></group9>
      </div>
      <div class="right">
        <group4 style=""></group4>
        <group5 style=""></group5>
        <group6 style=""></group6>
      </div>
    </div>
  </div>
</template>

<script>
import group from "@/views/bigscreen/group";
import group2 from "@/views/bigscreen/group2";
import group3 from "@/views/bigscreen/group3";
import group4 from "@/views/bigscreen/group4";
import group5 from "@/views/bigscreen/group5";
import group6 from "@/views/bigscreen/group6";
import group7 from "@/views/bigscreen/group7";
import group8 from "@/views/bigscreen/group8";
import group9 from "@/views/bigscreen/group9";
export default {
    data() {
    return {
      options: [{
          value: '221027388',
          label: '昆山园区'
        }, {
          value: '221027423',
          label: '车间北配电房'
        }, {
          value: '221027422',
          label: '行政楼配电房'
        }, {
          value: '221027390',
          label: '能源楼配电房'
        },],
        value: '昆山园区'
      }
    },
    created(){

    },
    components: {
      group,
      group2,
      group3,
      group5,
      group6,
      group4,
      group7,
      group8,
      group9
    },
    methods: {
        delCard(value) {
            console.log(value)
            this.$store.commit("setbigdeptcode", value)

        },
    },
    computed:{
        
    },
    mounted() {
      
 
    }
}
</script>

<style lang="less" scoped>
.bigheard{
  background-image: url(../../assets/imgs/big/header.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.bigbg{
  width: 100%;
  height: 100% !important;
  padding-left: 20px;
  padding-right: 20px;
  background-image: url(../../assets/imgs/big/bg_new.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.top{
    width: 100%;
    height: 10%;
}
.main{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-between;
}
.mind{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.left{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.right{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
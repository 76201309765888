<template>
  <div style="width: 100%;height: 33%;">
    <div class="grouptitle">电度电费分析</div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
    height: 16px; height: 16px; margin-right: 8.5px"/>
    <chartBoard ref="chart1" style="    height: calc(100% - 50px);" :option="chartOption1" />
  </div>
</template>

<script>

export default {
  name: "powerBox19",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "bigdeptcode": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        {
          code: "按周",
          name: "周",
        },
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      timetType2: [
        {
          code: "按周",
          name: "周",
        },
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "1AT1_进线柜",
          name: "1AT1_进线柜",
        },
        {
          code: "1AT2_进线柜",
          name: "1AT2_进线柜",
        },
        {
          code: "1AT3_进线柜",
          name: "1AT3_进线柜",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按周", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "1AT1_进线柜", //选中的项目
      },
      newarr1:[],
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      gridLeft: [],
      colorList: ["#17A735", "#3366FF", "#F5A623", "#686868"],
    };
  },
  computed: {
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "minute15",
          startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {
      let data = {
        deptCode: this.bigdeptcode,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps/ectricity/electric/degree/analysis",
        method: "post",
        data,
      })
        .then((res) => {
          this.detailInfo = {};
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
          this.detailInfo.chart1 = chart1;
          let newarr = [];
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          if (this.getNull(newarr) == newarr.length) {
            this.gridLeft = [0, 0, 0, 45];
          } else {
            this.gridLeft = [0, 0, 0, 5];
          }
          this.detailInfo.level = result.level.toString();
          this.detailInfo.tip = result.tip.toString();
          this.detailInfo.valley = result.valley.toString();
          this.detailInfo.peak = result.peak.toString();
          let arr = ['谷','尖峰','平','峰'];
          this.newarr1 = []
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 3), unit: "万" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 2), unit: "万" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "万" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "元" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "元" });
                } else {
                  this.newarr1.push({ value:element, unit: "元" });
                }
              }
            }
          }
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      let vm = this;
      var dom = this.$refs["chart1"];
      setTimeout(() => {
        if (!!dom) {
          dom.handleWindowResize();
        }
      }, 30);
      var { categoryData, seriesData } = this.detailInfo.chart1;
      var echarts = this.$echarts;
      var series = [];
      seriesData.forEach((item, idx) => {
        var obj = {
          type: "line",
          name: item.name,
          data: item.value,
          smooth: true,
          emphasis: {
            focus: "series",
          },

          symbol: "circle",
        };

        series.push(obj);
      });
      console.log(seriesData);
      let option = {};
      if (this.searchObj.TYPE === "按天") {
        option = {
          animation: true,
          layoutAnimation: false,
          grid: {
            left: "2%",
            right: "3%",
            bottom: "7%",
            top: "15%",
            containLabel: true,
          },
          legend: {
            right: 0,
            top: "0%",
            //orient: "vertical",
            icon: "rect", //图例形状
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            formatter: function (params) {
              var str = "";
              if (
                (params[0].value !== undefined ||
                  params[1].value !== undefined ||
                  params[2].value !== undefined ||
                  params[3].value !== undefined) &&
                (params[0].value !== 0 ||
                  params[1].value !== 0 ||
                  params[2].value !== 0 ||
                  params[3].value !== 0)
              ) {
                str += params[0].name;
              }
              const map = params.map((item, index) => {
                if (
                  item.value != "0" &&
                  item.value != 0 &&
                  item.value !== undefined
                ) {
                  str +=
                    "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                    "<span>" +
                    item.seriesName +
                    " : </span>" +
                    "<span>" +
                    item.value +
                    "</span>" +
                    "</div>";
                  return item;
                }
              });
              params = map;
              return str;
            },
          },
          xAxis: {
            type: "category",
            data: categoryData,
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
          },
          yAxis: {
            name: "电费（元）",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              padding: this.gridLeft,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              formatter(v) {
                v = v.toString();
                if (v >= 100000000000) {
                  return v.substring(0, 5) / 10 + "亿";
                } else if (v >= 10000000000) {
                  return v.substring(0, 4) / 10 + "亿";
                } else if (v >= 1000000000) {
                  return v.substring(0, 3) / 10 + "亿";
                } else if (v >= 100000000) {
                  return v.substring(0, 2) / 10 + "亿";
                } else if (v >= 10000000) {
                  return v.substring(0, 4) + "万";
                } else if (v >= 1000000) {
                  return v.substring(0, 3) + "万";
                } else if (v >= 100000) {
                  return v.substring(0, 2) + "万";
                } else if (v >= 10000) {
                  return v.substring(0, 2) / 10 + "万";
                } else if (v >= 1000) {
                  return v;
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 7) / 10 + "亿";
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 6) / 10 + "亿";
                } else if (v <= -10000000000) {
                  return "-" + v.substring(1, 5) / 10 + "亿";
                } else if (v <= -1000000000) {
                  return "-" + v.substring(1, 4) / 10 + "亿";
                } else if (v <= -100000000) {
                  return "-" + v.substring(1, 3) / 10 + "亿";
                } else if (v <= -10000000) {
                  return "-" + v.substring(1, 5) + "万";
                } else if (v <= -1000000) {
                  return "-" + v.substring(1, 4) + "万";
                } else if (v <= -100000) {
                  return "-" + v.substring(1, 3) + "万";
                } else if (v <= -10000) {
                  return "-" + v.substring(1, 3) / 10 + "万";
                } else if (v <= -1000) {
                  return v;
                } else {
                  return v;
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 200,
            // max: 1000
          },

          // 控住柱状图样式
          // series:series,
          series: [
            {
              type: "line",
              animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
              name: seriesData[0].name,
              data: seriesData[0].value,
              smooth: 0.2,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CEE8E2",
                  },
                  {
                    offset: 1,
                    color: "#CEE8E2",
                  },
                ]),
              },
            },
            {
              type: "line",
              animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
              name: seriesData[1].name,
              data: seriesData[1].value,
              // smooth: 0.2,
              smooth: false,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CCD9FF",
                  },
                  {
                    offset: 1,
                    color: "#CCD9FF",
                  },
                ]),
              },
            },
            {
              type: "line",
              animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
              name: seriesData[2].name,
              data: seriesData[2].value,
              // smooth: 0.2,
              smooth: false,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#FCE8C8",
                  },
                  {
                    offset: 1,
                    color: "#FCE8C8",
                  },
                ]),
              },
            },
            {
              type: "line",
              animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
              name: seriesData[3].name,
              data: seriesData[3].value,
              smooth: 0.2,
              smooth: false,

              lineStyle: {
                width: 2,
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#D9D9D9",
                  },
                  {
                    offset: 1,
                    color: "#D9D9D9",
                  },
                ]),
              },
            },
          ],
          color: this.colorList,
        };
      } else {
        option = {
          animation: true,
          layoutAnimation: false,
          grid: {
            left: "2%",
            right: "3%",
            bottom: "7%",
            top: "15%",
            containLabel: true,
          },
          legend: {
            right: 0,
            top: "0%",
            //orient: "vertical",
            icon: "rect", //图例形状
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            formatter: function (params) {
              var str = "";
              if (
                (params[0].value !== undefined ||
                  params[1].value !== undefined ||
                  params[2].value !== undefined ||
                  params[3].value !== undefined) &&
                (params[0].value !== 0 ||
                  params[1].value !== 0 ||
                  params[2].value !== 0 ||
                  params[3].value !== 0)
              ) {
                str += params[0].name;
              }
              let total = 0;
              params.forEach((item, index) => {
                if (item.value) {
                  total += Number(item.value);
                }
              });
              str +=
                "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                "<span>" +
                "总计" +
                " : </span>" +
                "<span>" +
                total.toFixed(2) +
                "</span>" +
                "</div>";

              const map = params.map((item, index) => {
                total += Number(item.value);
                if (
                  item.value != "0" &&
                  item.value != 0 &&
                  item.value !== undefined
                ) {
                  str +=
                    "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                    "<span>" +
                    item.seriesName +
                    " : </span>" +
                    "<span>" +
                    item.value +
                    "</span>" +
                    "</div>";
                  return item;
                }
              });
              params = map;
              return str;
            },
          },
        
          xAxis: {
            type: "category",
            data: categoryData,
            boundaryGap: true,
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
          },
          yAxis: {
            name: "电费（元）",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              padding: this.gridLeft,
            },
            axisLine: {
              lineStyle: {
                color: "#1f1f1f",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              formatter(v) {
                v = v.toString();
                if (v >= 100000000000) {
                  return v.substring(0, 5) / 10 + "亿";
                } else if (v >= 10000000000) {
                  return v.substring(0, 4) / 10 + "亿";
                } else if (v >= 1000000000) {
                  return v.substring(0, 3) / 10 + "亿";
                } else if (v >= 100000000) {
                  return v.substring(0, 2) / 10 + "亿";
                } else if (v >= 10000000) {
                  return v.substring(0, 4) + "万";
                } else if (v >= 1000000) {
                  return v.substring(0, 3) + "万";
                } else if (v >= 100000) {
                  return v.substring(0, 2) + "万";
                } else if (v >= 10000) {
                  return v.substring(0, 2) / 10 + "万";
                } else if (v >= 1000) {
                  return v;
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 7) / 10 + "亿";
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 6) / 10 + "亿";
                } else if (v <= -10000000000) {
                  return "-" + v.substring(1, 5) / 10 + "亿";
                } else if (v <= -1000000000) {
                  return "-" + v.substring(1, 4) / 10 + "亿";
                } else if (v <= -100000000) {
                  return "-" + v.substring(1, 3) / 10 + "亿";
                } else if (v <= -10000000) {
                  return "-" + v.substring(1, 5) + "万";
                } else if (v <= -1000000) {
                  return "-" + v.substring(1, 4) + "万";
                } else if (v <= -100000) {
                  return "-" + v.substring(1, 3) + "万";
                } else if (v <= -10000) {
                  return "-" + v.substring(1, 3) / 10 + "万";
                } else if (v <= -1000) {
                  return v;
                } else {
                  return v;
                }
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                width: 0.5,
                color: "#bababa",
              },
            },
            // 控制数据参数最大值和最小值
            // interval: 200,
            // max: 1000
          },

          // 控住柱状图样式
          // series:series,
          series: [
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[0].name,
              data: seriesData[0].value,
              stack: "总量",
              barWidth: "20%",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CEE8E2",
                  },
                  {
                    offset: 1,
                    color: "#CEE8E2",
                  },
                ]),
              },
            },
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[1].name,
              data: seriesData[1].value,
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#CCD9FF",
                  },
                  {
                    offset: 1,
                    color: "#CCD9FF",
                  },
                ]),
              },
            },
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[2].name,
              stack: "总量",
              data: seriesData[2].value,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#FCE8C8",
                  },
                  {
                    offset: 1,
                    color: "#FCE8C8",
                  },
                ]),
              },
            },
            {
              type: "bar",
              animationDurationUpdate: 2000,
              animationEasingUpdate: "quinticInOut",
              name: seriesData[3].name,
              data: seriesData[3].value,
              stack: "总量",
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#D9D9D9",
                  },
                  {
                    offset: 1,
                    color: "#D9D9D9",
                  },
                ]),
              },
            },
          ],
          color: this.colorList,
        };
      }

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style >
.grouptitle{
  color: white;
  font-size: 0.2rem;
}
</style>
<template>
  <div style="width: 100%;height: 33%;">
    <div class="grouptitle">综合能源总收益</div>
    <img src="@/assets/imgs/big/xian.svg" style="width: 100%;
    height: 16px; height: 16px; margin-right: 8.5px"/>
    <div style="display: flex;justify-content: space-between;">
      <div class="pai"></div>
      <div class="pai"></div>
      <div class="pai"></div>
      <div style="color: white;font-size: 3rem;">,</div>
      <div class="pai"></div>
      <div class="pai">{{num[5]?num[5]:''}}</div>
      <div class="pai">{{num[4]?num[4]:''}}</div>
      <div style="color: white;font-size: 3rem;">,</div>
      <div class="pai">{{num[2]?num[2]:''}}</div>
      <div class="pai">{{num[1]?num[1]:''}}</div>
      <div class="pai">{{num[0]?num[0]:''}}</div>
      <div style="color: #aaa7a7;
    font-size: 20px;
    line-height: 50px;">元</div>
    </div>
    
    <chartBoard ref="chart1" style="    height: calc(100% - 50px);" :option="chartOption1" />
  </div>
</template>

<script>

export default {
  name: "powerBox20",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "bigdeptcode": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      num:0,
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        {
          code: "按周",
          name: "周",
        },
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "1AT1_进线柜",
          name: "1AT1_进线柜",
        },
        {
          code: "1AT2_进线柜",
          name: "1AT2_进线柜",
        },
        {
          code: "1AT3_进线柜",
          name: "1AT3_进线柜",
        },
      ],
      gridLeft1: [],
      panelOpen: false,
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "1AT1_进线柜", //选中的项目
      },
      gridLeft: "",
      newarr1:[],
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#90ED7D", "#7CB5EC", "#444449"],
    };
  },
  computed: {
    // 当前Dept信息
    bigdeptcode() {
      return this.$store.state.bigdeptcode;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day",
          startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {
      
      let data = {
        deptCode: this.bigdeptcode,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps/ectricity/electric/income",
        method: "post",
        data,
      })
        .then((res) => {
          this.detailInfo = {};
          let result = res.data.data;
          console.log(result);
          var chart1 = {
            categoryData: result.categonyData,
            seriesData: result.seriesData,
          };
          let a = 0
          if(result.self !='NaN'){
            a = a+result.self
          }
          if(result.storage !='NaN'){
            a = a+result.storage
          }
          if(result.surplus !='NaN'){
            a = a+result.surplus
          }
          console.log(parseInt(a))
          let str = this.toThousandsSeparator(parseInt(a))
          this.num = str.split("").reverse()
         
          console.log(this.num)
          
          let newarr = [];
          this.detailInfo.chart1 = chart1;
          this.detailInfo.surplus = result.surplus.toString();
          this.detailInfo.self = result.self.toString();
          this.detailInfo.storage = result.storage.toString();
          let arr = ['余电上网','自发自用','储能',];
          this.newarr1 = []
          for (const i in this.detailInfo) {
            if (this.detailInfo.hasOwnProperty.call(this.detailInfo, i)) {
              const element = this.detailInfo[i];
              console.log(element);
              if (typeof element == "string") {
                if (element >= 100000000000) {
                  this.newarr1.push({ value: element.substring(0, 5) / 10, unit: "亿" });
                } else if (element >= 10000000000) {
                  this.newarr1.push({ value: element.substring(0, 4) / 10, unit: "亿" });
                } else if (element >= 1000000000) {
                  this.newarr1.push({ value: element.substring(0, 3) / 10, unit: "亿" });
                } else if (element >= 100000000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "亿" });
                } else if (element >= 10000000) {
                  this.newarr1.push({ value: element.substring(0, 4), unit: "万" });
                } else if (element >= 1000000) {
                  this.newarr1.push({ value: element.substring(0, 3), unit: "万" });
                } else if (element >= 100000) {
                  this.newarr1.push({ value: element.substring(0, 2), unit: "万" });
                } else if (element >= 10000) {
                  this.newarr1.push({ value: element.substring(0, 2) / 10, unit: "万" });
                } else if (element >= 1000) {
                  this.newarr1.push({ value: element, unit: "元" })
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 7) / 10, unit: "亿" });
                } else if (element <= -100000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 6) / 10, unit: "亿" });
                } else if (element <= -10000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5) / 10, unit: "亿" });
                } else if (element <= -1000000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4) / 10, unit: "亿" });
                } else if (element <= -100000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3) / 10, unit: "亿" });
                } else if (element <= -10000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 5), unit: "万" });
                } else if (element <= -1000000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 4), unit: "万" });
                } else if (element <= -100000) {
                  this.newarr1.push({ value:"-" + element.substring(1, 3), unit: "万" });
                } else if (element <= -10000) {
                  this.newarr1.push({ value:"-" + element.substring(1,3) / 10, unit: "万" });
                } else if (element <= -1000) {
                  this.newarr1.push({ value:element, unit: "元" });
                } else {
                  this.newarr1.push({ value:element, unit: "元" });
                }
              }
            }
          }
          this.newarr1.forEach((item,index)=>{
            item['name'] = arr[index]
          })
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          if (this.getNull(newarr) == newarr.length) {
            this.gridLeft = "2%";
            this.gridLeft1 = [0, 0, 0, 60];
          } else {
            this.gridLeft = "1%";
            this.gridLeft1 = [0, 0, 0, 0];
          }
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
          currentValue === null ||
          JSON.parse(currentValue) === null ||
          typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      var dom = this.$refs["chart1"];
      setTimeout(() => {
        if (!!dom) {
          dom.handleWindowResize();
        }
      }, 33);
      //颜色16进制换算rgba,添加透明度
function hexToRgba(hex, opacity) {
    return (
        'rgba(' +
        parseInt('0x' + hex.slice(1, 3)) +
        ',' +
        parseInt('0x' + hex.slice(3, 5)) +
        ',' +
        parseInt('0x' + hex.slice(5, 7)) +
        ',' +
        opacity +
        ')'
    );
}

let backgroundColor = 'rgba(0,0,0,0)';
// 数据
let chartdata = [
    {
        name: '自发自用',
        value: this.detailInfo.self !="NaN"?this.detailInfo.self:0,
    },
    {
        name: '余电上网',
        value: this.detailInfo.surplus!="NaN"?this.detailInfo.surplus:0,
    },
    {
        name: '储能',
        value: this.detailInfo.storage!="NaN"?this.detailInfo.storage:0,
    },
];
console.log(chartdata)
let chartdata2 = {
  "自发自用":{
    value:this.detailInfo.self!="NaN"?this.detailInfo.self:0
  },
  "余电上网":{
    value:this.detailInfo.surplus!="NaN"?this.detailInfo.surplus:0
  },
  "储能":{
    value:this.detailInfo.storage!="NaN"?this.detailInfo.storage:0
  }
}
let radius = ['30%', '45%'];
// 颜色系列
let color = ['#37FFC9', '#FFE777', '#19D6FF', '#32A1FF', '#cccccc', '#ff1111'];
let labelshow = true;
let centerimg = true;
let lineshow = false;
let color1 = [],
    color2 = [],
    color3 = [];
// 设置每层圆环颜色和添加间隔的透明色
color.forEach((item) => {
    color1.push(item, 'transparent');
    color2.push(hexToRgba(item, 0.7), 'transparent');
    color3.push(hexToRgba(item, 0.4), 'transparent');
});
let data1 = [];
let sum = 0;
// 根据总值设置间隔值大小
chartdata.forEach((item) => {
    sum += Number(item.value);
});
// 给每个数据后添加特定的透明的数据形成间隔
chartdata.forEach((item, index) => {
    if (item.value !== 0) {
        data1.push(item, {
            name: '',
            value: sum / 70,
            labelLine: {
                show: false,
                lineStyle: {
                    color: 'transparent',
                },
            },
            itemStyle: {
                color: 'transparent',
            },
        });
    }
});
// 每层圆环大小
let radius2 = [Number(radius[1].split('%')[0]) + 0 + '%', Number(radius[1].split('%')[0]) + 15 + '%'];
let radius3 = [Number(radius[1].split('%')[0]) + 15 + '%', Number(radius[1].split('%')[0]) + 24 + '%'];
let option = {
    backgroundColor: backgroundColor,
    grid: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        containLabel: true,
    },
    legend: {
        show: true,
        top: "center",
        left: '70%',
        data: ['自发自用','余电上网','储能'],
        itemWidth: 10,
        itemHeight: 10,
        width:50,
        textStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 13,
            },
        formatter: function(name) {
            return "" + name + "  " + (chartdata2[name].value) + ""
        },
        // textStyle: {
        //     rich: {
        //         title: {
        //             fontSize: 10,
        //             lineHeight: 10,
        //             color: "rgba(0,0,0,.45)"
        //         },
        //         value: {
        //             fontSize: 14,
        //             lineHeight: 18,
        //             color: "rgba(0,0,0,.85)"
        //         }
        //     }
        // },
    },
    tooltip: {
        formatter: (params) => {
            if (params.name !== '') {
                return params.name + ' : ' + params.value + '\n' + '(' + params.percent + '%)';
            }
        },
    },
    series: [
        // 最外层圆环
        {
            type: 'pie',
            radius: radius3,
            center: ['20%', '50%'],
            hoverAnimation: false,
            startAngle: 90,
            selectedMode: 'single',
            selectedOffset: 0,
            itemStyle: {
                normal: {
                    color: (params) => {
                        return color1[params.dataIndex];
                    },
                },
            },
            label: {
                show: false,
                position: 'outside',
                formatter: (params) => {
                    let zzb = 0;
                    data1.forEach((item, index) => {
                        // 当前值一半加上前面的值是否大于50%（判断label朝向）
                        if (index <= params.dataIndex && item.name != '') {
                            if (index == params.dataIndex) {
                                zzb += Number(item.value) / 2;
                            } else {
                                zzb += Number(item.value);
                            }
                        }
                    });
                    if (params.name != '') {
                        // 若当前值一半加上前面的值的占比大于0.5三角形朝右，相反朝左
                        if (zzb / sum > 0.5) {
                            return '{txt|' + ((params.value * 100) / sum).toFixed(0) + '%}' + '\n{san|▶}';
                        } else {
                            return '{txt|' + ((params.value * 100) / sum).toFixed(0) + '%}' + '\n{san|◀}';
                        }
                    }
                },
                align: 'left',
                padding: [0, -50, 10, -60],
                rich: {
                    txt: {
                        color: '#fff',
                        width: 10,
                        height: 10,
                        padding: [10, 10, 0, 24],
                    },
                    san: {
                        padding: [12, -3, -20, 10],
                    },
                },
            },
            labelLine: {
                show: true,
                length: 15,
                length2: 60,
                lineStyle: {
                    color: '#fff',
                    width: 2,
                },
            },
            data: data1,
            z: 666,
        },
        {
            type: 'pie',
            radius: radius2,
            center: ['20%', '50%'],
            hoverAnimation: false,
            startAngle: 90,
            selectedMode: 'single',
            selectedOffset: 0,
            itemStyle: {
                normal: {
                    color: (params) => {
                        return color2[params.dataIndex];
                    },
                },
            },
            label: {
                show: false,
                formatter: '{b}' + ' ' + '{c}',
            },
            data: data1,
            z: 666,
        },
        {
            type: 'pie',
            radius: radius,
            center: ['20%', '50%'],
            hoverAnimation: false,
            startAngle: 90,
            selectedMode: 'single',
            selectedOffset: 0,
            itemStyle: {
                normal: {
                    color: (params) => {
                        return color3[params.dataIndex];
                    },
                },
            },
            label: {
                show: false,
                formatter: '{b}' + ' ' + '{c}',
            },
            data: data1,
            z: 666,
        },
    ],
};
      
      
      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      let vm = this
      setTimeout(() => {
        vm.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style >
.grouptitle{
  color: white;
  font-size: 0.2rem;
}
.pai{
  width: 2.5rem;
  height: 3.3rem;
  background: #142327;
  border: 2px solid #346f71;
  color: white;
  font-size: 2rem;
  text-align: center;
  line-height: 3.3rem;
}
</style>